import "./reset.css"
import "./styles.css"

import React, { Fragment } from "react"
import { Link } from "gatsby"

export default () => (
  <Fragment>
    <div className="not-found">
      <img src="/not-found/404.svg" alt="not found" />
      <h1>not found</h1>
      <Link to="/">go to home page</Link>
    </div>
  </Fragment>
)
